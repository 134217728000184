import React, { useState, useEffect } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import improveProductivity from "../../../assets/images/Icons/SVG/Selected Org.svg";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import API from "../../../Api/Api";
import RouteName from "../../../Routes/Routename";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SetObjective = (props) => {
  const navigate = useNavigate();
  const [showList, setShowList] = useState(false);
  const [arrayList, setArrayList] = useState([]);
  const [isEditing, setEditing] = useState(false); // New state for edit mode
  const [editingId, setEditingId] = useState(null); // New state for the currently editing objective
  // const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    getAllObjectives();
  }, []);

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  const deleteDepartment = () => {
    try {
      API.Auth.DeactivateObjective({
        data: { _id: deleteId },
      }).then((response) => {
        const newObjectiveId = response.data.data._id;
        const updatedArray = arrayList.filter(
          (item) => item._id !== newObjectiveId
        );
        setArrayList(updatedArray);
        document.querySelector("#DeleteDepartemnt").click();
        formik.resetForm();
        setEditing(false);
        setEditingId("");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (id) => {
    const objectiveToEdit = arrayList.find((item) => item._id === id);
    if (objectiveToEdit) {
      formik.setValues({
        name: objectiveToEdit.objective_name,
        description: objectiveToEdit.description,
      });
      setEditing(true);
      setEditingId(id);
    }
  };

  const handleUpdate = () => {
    const isMatching = arrayList.some(
      (item) =>
        item.objective_name === formik.values.name &&
        item?.description === formik.values.description && item._id === editingId
    );

    if (isMatching) {
      formik.resetForm();
      setEditing(false);
      setEditingId("");
      // toast.error(response?.data?.message);

    } else {
      API.Auth.UpdateObjective({
        data: {
          _id: editingId,
          objective_name: formik.values.name,
          description: formik.values.description,
        },
      }).then((response) => {
        if (response?.data?.statusCode === 200) {
          getAllObjectives();
          formik.resetForm();
          setEditing(false);
          setEditingId("");
        } else {
          // Handle error or provide feedback to the user
          toast.error(response?.data?.message);
        }
      });
    }
  };

  const getAllObjectives = () => {
    try {
      API.Auth.GetAllOrgObjectiveAPI().then((response) => {
        setArrayList(response.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (values) => {
    const spaceReg = /^\S.*\S$/;
    const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

    const errors = {};
    if (!values.name) {
      errors.name = "Please enter objective";
    } else if (!spaceReg.test(values.name)) {
      errors.name = "Cannot start & end with a space";
    }
    // else if (values.name.length > 30) {
    //   errors.name = "Max 30 Characters";
    // }

    if (!values.description) {
      errors.description = "Please enter description";
    } else if (!paragraphReg.test(values.description)) {
      errors.description = "Cannot start & end with a space";
    }
    // else if (values.description.length > 100) {
    //   errors.description = "Max 100 Characters";
    // }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    onSubmit: () => {
      if (editingId) {
        handleUpdate();
      } else {
        createObjective();
      }
    },
    validate,
  });
  const createObjective = () => {
    API.Auth.CreateOrgObjectiveAPI({
      data: {
        objective_name: formik.values.name,
        description: formik.values.description,
        flag: 3,
      },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          formik.resetForm();
          setShowList(true);
          getAllObjectives(); // Refresh the list after submitting a new objective
        } else {
          // Handle error or provide feedback to the user
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNextButton = () => {
    props.handleNext();

    navigate(`../${RouteName.Auth.Registration}?id=3`, { replace: true });
  };
  const handleCancel = (event) => {
    event.preventDefault();
    formik.resetForm();
    setEditing(false);
    setEditingId("");
  };
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Create Objective</h2>
          <p className="textGrey">
            Make sure your objectives are crisp and clear
          </p>
          <div className="row">
            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
              {/* <p className="textBlueLight">Enter your objectives</p> */}
              {/* <p className="textLightGrey">Objective 1</p> */}

              <form>
                <div className="mb-4">
                  <div className="form-group">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label textLightGrey mb-1"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      // maxLength="30"
                      className={`form-control`}
                      placeholder="Eg: Improve Productivity"
                      id="exampleInputfirstname"
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="mb-4">
                  <div className="form-group">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label textLightGrey mb-1"
                    >
                      Description
                    </label>

                    <textarea
                      className={`form-control`}
                      type="text"
                      name="description"
                      placeholder="Write a one-liner description of the above objective..."
                      rows="4"
                      // maxLength="100"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                    ></textarea>
                    {formik.errors.description && formik.touched.description ? (
                      <div className="text-danger">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
                {!isEditing ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn set-objective-button w-100"
                      onClick={formik.handleSubmit}
                    >
                      Add Objective
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn set-objective-button px-5"
                      onClick={(event) => handleCancel(event)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-white px-5"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
              <p className="textBlueLight mt-3">
                <span className="text-decoration-underline">Note:</span> We
                suggest you add not more than 5 objectives making it achievable.
              </p>
            </div>
            <div className="col-xl-6 ps-xl-5">
              <p className="textBlueLight">List of all your objectives</p>
              {arrayList?.length == 0 ? (
                <div className="row">
                  <div className="col-12 text-center py-5">
                    <img src={setObjective} />
                  </div>
                </div>
              ) : (
                <>
                  {arrayList?.map((item, index) => (
                   
                      <li
                        key={index}
                        className="row d-flex align-items-center mb-3"
                      >
                        <div className="col-9">
                          <div className="setObjectiveListBox row p-3">
                            <div className="col-2 p-0">
                              <img
                                src={improveProductivity}
                                alt="Improve Productivity"
                              />
                            </div>
                            <div className="col-10 setObjectiveListBox">
                              <h4 className="textBlueLight1">
                                {item.objective_name}
                              </h4>
                              <p className="textSecondary">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="d-flex">
                            <img
                              src={editIcon}
                              alt="Edit Icon"
                              onClick={() => handleEdit(item._id)}
                            />
                            <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => handleDelete(item._id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                          </div>
                        </div>
                      </li>
                  
                  ))}
                </>
              )}

              {/* <button className="btn sign-up-button-color-onempty text-light w-100 mt-3" onClick={()=>handleNextButton()}>
                Next
              </button> */}

              <button
                className={`btn ${
                  arrayList?.length === 0
                    ? "sign-up-button-color-onempty"
                    : "sign-up-button-color"
                } text-light w-100 mt-3`}
                onClick={() => handleNextButton()}
                disabled={arrayList?.length === 0}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
    // </div>
  );
};

export default SetObjective;
