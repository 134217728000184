

const useTaskScoreAverage = () => {
    const getTaskScoreAverage =  (inputArray) => {
      const { sum, count } = inputArray.reduce(
        (accumulator, currentValue) => {
          return {
            sum: accumulator.sum + currentValue.current_score,
            count: accumulator.count + 1,
          };
        },
        { sum: 0, count: 0 }
      );
      const average = count !== 0 ? sum / count : 0; 
  
      return average;
    };
  
    return { getTaskScoreAverage };
  };
  
  export default useTaskScoreAverage;
  