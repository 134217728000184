import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import otpSentIcon from "../../assets/images/Icons/Pics/Icon awesome-check-circle.png";
// import setObjective from "../../assets/images/Icons/Pics/Set Objective.png";
// import improveProductivity from "../../assets/images/Icons/SVG/Selected Org.svg";
// import editIcon from "../../assets/images/Icons/SVG/Edit Icon.svg";
// import deleteIcon from "../../assets/images/Icons/SVG/Delete Icon.svg";
// import departmentIcon from "../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
// import API from "../../Api/Api";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CompanyDetail from "./Registration/CompanyDetail";
import EmailVerification from "./Registration/EmailVerification";
import SetObjective from "./Registration/SetObjective";
import DefineDepartment from "./Registration/DefineDepartment";
import ShareAccess from "./Registration/ShareAccess";
import RouteName from "../../Routes/Routename";

const steps = [
  "Company Details",
  "Email Verification",
  "Set Objective",
  "Define Department",
  "Share access",
];

const Registration = () => {
  const [skipped, setSkipped] = React.useState(new Set());
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const check = queryParams.get("id");
  const email = queryParams.get("email");
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(parseInt(check));

  // useEffect(() => {
  //   if (check) {
  //     setActiveStep(check);
  //   } else {
  //     setActiveStep(0);
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log(activeStep);
  // }, [activeStep]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
  
    const nextStep = activeStep + 1;
    console.log('Next Step:', nextStep); // Check the value of nextStep

    setActiveStep(nextStep);
    setSkipped(newSkipped);
  
    navigate(`../${RouteName.Auth.Registration}?id=${nextStep}`, { replace: true });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <div className="login-page">
        <div className="container-fluid main-container vh-100 p-0">
          <div className="row h-100">
            <div className="col-12 vh-100 p-5">
              <h1 className="bannerHeading fs-3 mb-5">
                EASY<span className=" fs-3">OKR</span>
              </h1>
              <div className="row h-100 d-flex justify-content-center">
                {/* <LoginBanner /> */}

                <div className="col-xl-10 col-12 vh-100">
                  <div className="row justify-content-center login-form-container w-100 px-3">
                    <div className="col-xl-11 col-12 h-100">
                      <div className="d-flex align-items-center">
                        {/* <NavLink to="/">
                          <div className="backArrow me-2">
                            <i className="fa fa-solid fa-chevron-left textDark"></i>
                          </div>
                        </NavLink> */}
                        <h2 className="mb-0 textDark">Registration</h2>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <Box sx={{ width: "100%" }}>
                            <Stepper activeStep={activeStep}>
                              {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                  labelProps.optional = (
                                    <Typography variant="caption"></Typography>
                                  );
                                }
                                if (isStepSkipped(index)) {
                                  stepProps.completed = false;
                                }
                                return (
                                  <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                      {label}
                                    </StepLabel>
                                  </Step>
                                );
                              })}
                            </Stepper>
                            {activeStep === steps.length ? (
                              <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                  All steps completed - you&apos;re finished
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                  }}
                                >
                                  <Box sx={{ flex: "1 1 auto" }} />
                                  <Button onClick={handleReset}>Reset</Button>
                                </Box>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                          
                                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                {(parseInt(activeStep) === 0 ||
                                  activeStep === null) && (
                                  <div>
                                    <CompanyDetail handleNext={handleNext} />
                                  </div>
                                )}
                                {(parseInt(activeStep) === 1 ||
                                 activeStep === null) && (
                                  <div>
                                    <EmailVerification handleNext={handleNext} />
                                  </div>
                                )}
                                {parseInt(activeStep) === 2 && (
                                  <div>
                                    <SetObjective handleNext={handleNext} />
                                  </div>
                                )}
                                {parseInt(activeStep) === 3 && (
                                  <div>
                                    <DefineDepartment handleNext={handleNext} />
                                  </div>
                                )}
                                {parseInt(activeStep) === 4 && (
                                  <div>
                                    <ShareAccess handleNext={handleNext} handleBack={handleBack}  />
                                  </div>
                                )}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                  }}
                                >
                                  {/* <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                  >
                                    Back
                                  </Button> */}
                                  <Box sx={{ flex: "1 1 auto" }} />
                     

                                  {/* <Button onClick={handleNext}>
                                    {activeStep === steps.length - 1
                                      ? "Finish"
                                      : "Next"}
                                  </Button> */}
                                </Box>
                              </React.Fragment>
                            )}
                          </Box>
                        </div>
                        {/* <div className="col-12">
                          <section className="wizard-section">
                            <div className="row no-gutters">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-wizard">
                                  <form action="" method="post" role="form">
                                    <div className="form-wizard-header">
                                      <ul className="list-unstyled form-wizard-steps clearfix">
                                        <li className="active">
                                          <span>1</span>
                                          <p className="mb-0 mt-2">
                                            Company Details
                                          </p>
                                        </li>
                                        <li>
                                          <span>2</span>
                                          <p className="mb-0 mt-2">
                                            Email Verification
                                          </p>
                                        </li>
                                        <li>
                                          <span>3</span>
                                          <p className="mb-0 mt-2">
                                            Set Objective
                                          </p>
                                        </li>
                                        <li>
                                          <span>4</span>
                                          <p className="mb-0 mt-2">
                                            Define Department
                                          </p>
                                        </li>
                                        <li>
                                          <span>5</span>
                                          <p className="mb-0 mt-2">
                                            Share access
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <fieldset className="wizard-fieldset show">
                                      <h2 className="textBlue">
                                        Company Detail
                                      </h2>
                                      <div className="row mt-4">
                                        <div className="col-10">
                                          <form>
                                            <div className="mb-4">
                                              <div className="form-group">
                                                <label htmlFor="phoneno">
                                                  <span className="mandatory-star me-1">
                                                    *
                                                  </span>{" "}
                                                  Enter CIN
                                                </label>
                                                <input
                                                  type="number"
                                                  className="form-control mt-2"
                                                  placeholder="Enter 21 digit CIN"
                                                  id="exampleInputphoneno"
                                                  // value={cin}
                                                  // onChange={handleSetCin}
                                                />
                                              </div>
                                            </div>
                                            <div className="mb-4">
                                              <div className="form-group">
                                                <label htmlFor="firstname">
                                                  Company Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control mt-2"
                                                  placeholder="Company name"
                                                  id="exampleInputfirstname"
                                                  // value={name}
                                                  // onChange={handleSetName}
                                                />
                                              </div>
                                            </div>
                                            <div className="mb-4">
                                              <div className="form-group">
                                                <label htmlFor="Email1">
                                                  <span className="mandatory-star me-1">
                                                    *
                                                  </span>{" "}
                                                  Enter your work email address
                                                </label>
                                                <input
                                                  type="email"
                                                  className="form-control mt-2"
                                                  placeholder="Enter your email"
                                                  id="exampleInputEmail1"
                                                  aria-describedby="emailHelp"
                                                  // value={email}
                                                  // onChange={handleSetEmail}
                                                />
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <button
                                                type="submit"
                                                className="btn sign-up-button-color text-white px-5"
                                                // onClick={sendOtp}
                                              >
                                                Verify via OTP
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <fieldset className="wizard-fieldset">
                                      <div className="row justify-content-center mt-4">
                                        <div className="col-xl-5 col-lg-6 col-md-8 col-12">
                                          <h2 className="textBlue">
                                            Email Verification
                                          </h2>
                                          <div className="col-12 mb-4">
                                            <small className="textDark">
                                              Enter 4 digit OTP sent on the
                                              email address &#39;
                                              loremipsum@gmail.com &#39;.
                                            </small>
                                          </div>

                                          <div className="col-12">
                                            <div className="row mb-3 text-center">
                                              <div className="col-3">
                                                <input
                                                  placeholder="-"
                                                  type="text"
                                                  className="form-control custom-input-text-primary otp-input mb-0 text-center border-danger"
                                                />
                                              </div>
                                              <div className="col-3">
                                                <input
                                                  placeholder="-"
                                                  type="text"
                                                  className="form-control custom-input-text-primary otp-input mb-0 text-center border-danger"
                                                />
                                              </div>
                                              <div className="col-3">
                                                <input
                                                  placeholder="-"
                                                  type="text"
                                                  className="form-control custom-input-text-primary otp-input mb-0 text-center border-danger"
                                                />
                                              </div>
                                              <div className="col-3">
                                                <input
                                                  placeholder="-"
                                                  type="text"
                                                  className="form-control custom-input-text-primary otp-input mb-0 text-center border-danger"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          

                                          <button className="btn sign-up-button-color-onempty text-light w-100 mt-3">
                                            Verify
                                          </button>

                                          <div className="row mt-3">
                                            <div className="col-12">
                                              <span className="mt-3">
                                                Didn&#39;t receive OTP?
                                              </span>
                                              <br />
                                              <p className="fs-6 btn p-0 text-decoration-underline textDark">
                                                Resend OTP
                                              </p>
                                            </div>
                                          </div>

                                          <div className="login-form-container position-relative">
                                            <div className="text-center mb-5">
                                              <img src={otpSentIcon} />
                                            </div>
                                            <div className="text-center px-4 mt-2">
                                              <h2 className="text-center fw-bold letter-spacing">
                                                OTP sent successfully.
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <fieldset className="wizard-fieldset">
                                      <div className="row justify-content-center mt-4">
                                        <div className="col-xxl-11 col-12">
                                          <h2 className="textBlue fw-normal">
                                            Set Objective
                                          </h2>
                                          <p className="textGrey">
                                            Make sure your objectives are crisp
                                            and clear
                                          </p>
                                          <div className="row">
                                            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
                                              <p className="textBlueLight">
                                                Enter your objectives
                                              </p>
                                              <p className="textLightGrey">
                                                Objective 1
                                              </p>
                                              <form>
                                                <div className="mb-4">
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control mt-2"
                                                      placeholder="Eg: Improve Productivity"
                                                      id="exampleInputfirstname"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="mb-4">
                                                  <div className="form-group">
                                                    <textarea
                                                      className="form-control"
                                                      placeholder="Write a one liner description of the above objective..."
                                                      id="floatingTextarea"
                                                      rows="4"
                                                    ></textarea>
                                                  </div>
                                                </div>

                                                <div className="text-center">
                                                  <button
                                                    type="submit"
                                                    className="btn set-objective-button w-100"
                                                  >
                                                    Publish
                                                  </button>
                                                </div>
                                              </form>
                                              <p className="textBlueLight mt-3">
                                                <span className="text-decoration-underline">
                                                  Note:
                                                </span>{" "}
                                                We suggest you to add not more
                                                than 5 objectives making it
                                                achievable.
                                              </p>
                                            </div>
                                            <div className="col-xl-6 ps-xl-5">
                                              <p className="textBlueLight">
                                                List of all your objectives
                                              </p>
                                              <div className="row">
                                                <div className="col-12 d-flex justify-content-center align-items-center py-5">
                                                  <img src={setObjective} />
                                                </div>
                                              </div>
                                              <div className="row d-flex align-items-center">
                                                <div className="col-8">
                                                  <div className="setObjectiveListBox row p-3">
                                                    <div className="col-2 p-0">
                                                      <img
                                                        src={
                                                          improveProductivity
                                                        }
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="col-10 setObjectiveListBox">
                                                      <h4 className="textBlueLight1">
                                                        Improve Productivity
                                                      </h4>
                                                      <p className="textSecondary">
                                                        Lorem ipsum dolor sit
                                                        amet, consectetur
                                                        adipiscing
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2">
                                                  <div className="d-flex">
                                                    <img src={editIcon} />
                                                    <img src={deleteIcon} />
                                                  </div>
                                                </div>
                                              </div>
                                              <button className="btn sign-up-button-color-onempty text-light w-100 mt-3">
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <fieldset className="wizard-fieldset">
                                      <div className="row justify-content-center mt-4">
                                        <div className="col-xxl-11 col-12">
                                          <h2 className="textBlue fw-normal">
                                            Define Department
                                          </h2>
                                          <p className="textGrey">
                                            Make sure your objectives are crisp
                                            and clear
                                          </p>
                                          <div className="row">
                                            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
                                              <p className="textBlueLight">
                                                Select the department and assign
                                                it to the owners
                                              </p>

                                              <form>
                                                <div className="mb-4">
                                                  <div className="form-group">
                                                    <input
                                                      type="text"
                                                      className="form-control mt-2"
                                                      placeholder="Enter department name"
                                                      id="exampleInputfirstname"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="mb-4">
                                                  <div className="form-group">
                                                    <input
                                                      type="email"
                                                      className="form-control mt-2"
                                                      placeholder="Enter department owner's email address"
                                                      id="exampleInputfirstname"
                                                    />
                                                  </div>
                                                </div>

                                                <div className="text-center">
                                                  <button
                                                    type="submit"
                                                    className="btn set-objective-button w-100"
                                                  >
                                                    Publish
                                                  </button>
                                                </div>
                                              </form>
                                              <p className="textBlueLight mt-3">
                                                <span className="text-decoration-underline">
                                                  Note:
                                                </span>{" "}
                                                We suggest you to add not more
                                                than 5 objectives making it
                                                achievable.
                                              </p>
                                            </div>
                                            <div className="col-xl-6 ps-xl-5">
                                              <p className="textBlueLight">
                                                Here are the list of your
                                                departments and their
                                                departmental leaders
                                              </p>
                                              <div className="row">
                                                <div className="col-12 text-center py-5">
                                                  <img src={setObjective} />
                                                </div>
                                              </div>
                                              <br />
                                              <div className="row d-flex align-items-center w-100">
                                                <div className="col-8">
                                                  <div className="setObjectiveListBox row p-3">
                                                    <div className="col-2 p-0">
                                                      <img
                                                        src={departmentIcon}
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="col-10 setObjectiveListBox">
                                                      <p className="mb-1">
                                                        Human Resource
                                                      </p>
                                                      <p className="textBlueLight1 mb-0">
                                                        urvashi.gupta@qodequay.com
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2">
                                                  <div className="d-flex">
                                                    <img src={editIcon} />
                                                    <img src={deleteIcon} />
                                                  </div>
                                                </div>
                                              </div>
                                              <button className="btn sign-up-button-color-onempty text-light w-100 mt-3">
                                                Next
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <fieldset className="wizard-fieldset">
                                      <div className="row justify-content-center mt-4">
                                        <div className="col-xxl-11 col-12">
                                          <h2 className="textBlue fw-normal">
                                            Share Invite
                                          </h2>
                                          <p className="textGrey">
                                            Make sure your objectives are crisp
                                            and clear
                                          </p>
                                          <div className="row">
                                            <div className="col-xl-6">
                                              <div className="row d-flex align-items-center">
                                                <div className="col-8">
                                                  <div className="setObjectiveListBox row p-3">
                                                    <div className="col-2 p-0">
                                                      <img
                                                        src={departmentIcon}
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="col-10 setObjectiveListBox">
                                                      <p className="mb-1">
                                                        Human Resource
                                                      </p>
                                                      <p className="textBlueLight1 mb-0">
                                                        urvashi.gupta@qodequay.com
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2">
                                                  <div className="d-flex">
                                                    <img src={editIcon} />
                                                    <img src={deleteIcon} />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-xl-6 ">
                                              <div className="row d-flex align-items-center">
                                                <div className="col-8">
                                                  <div className="setObjectiveListBox row p-3">
                                                    <div className="col-2 p-0">
                                                      <img
                                                        src={departmentIcon}
                                                        className=""
                                                      />
                                                    </div>
                                                    <div className="col-10 setObjectiveListBox">
                                                      <p className="mb-1">
                                                        Human Resource
                                                      </p>
                                                      <p className="textBlueLight1 mb-0">
                                                        urvashi.gupta@qodequay.com
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-2">
                                                  <div className="d-flex">
                                                    <img src={editIcon} />
                                                    <img src={deleteIcon} />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 text-center">
                                         
                                          <button
                                            type="button"
                                            className="btn btn sign-up-button-color text-white px-5 mt-3"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                          >
                                            Share Invite
                                          </button>
                                        
                                          <div
                                            className="modal fade"
                                            id="staticBackdrop"
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                          >
                                            <div className="modal-dialog modal-dialog-centered">
                                              <div className="modal-content border-0 p-4">
                                                <div className="modal-header py-0 border-0">
                                                  <h2 className="textBlue fw-normal">
                                                    Tell us about your company
                                                  </h2>
                                                  <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                  />
                                                </div>
                                                <div className="modal-body text-start">
                                                  <form>
                                                    <div className="mb-4">
                                                      <div className="form-group">
                                                        <label htmlFor="phoneno">
                                                          <span className="mandatory-star me-1">
                                                            *
                                                          </span>
                                                          About your Company
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control mt-2"
                                                          placeholder="Describe your company...."
                                                          id="exampleInputfirstname"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="row">
                                                      <div className="col-6">
                                                        <div className="mb-4">
                                                          <div className="form-group">
                                                            <label htmlFor="phoneno">
                                                              <span className="mandatory-star me-1">
                                                                *
                                                              </span>
                                                              Company Mission
                                                            </label>
                                                            <textarea
                                                              className="form-control"
                                                              placeholder="Mention your Company Mission..."
                                                              id="floatingTextarea"
                                                              rows="2"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-6">
                                                        <div className="mb-4">
                                                          <div className="form-group">
                                                            <label htmlFor="phoneno">
                                                              <span className="mandatory-star me-1">
                                                                *
                                                              </span>
                                                              Company Vision
                                                            </label>
                                                            <textarea
                                                              className="form-control"
                                                              placeholder="Mention your Company Vision..."
                                                              id="floatingTextarea"
                                                              rows="2"
                                                            ></textarea>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="row">
                                                      <label htmlFor="phoneno">
                                                        <span className="mandatory-star me-1">
                                                          *
                                                        </span>
                                                        Mention the 3
                                                        differentiator of your
                                                        company ?
                                                      </label>
                                                      <div className="col-4">
                                                        <div className="mb-4">
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control mt-2"
                                                              placeholder="Key Factor"
                                                              id="exampleInputfirstname"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-4">
                                                        <div className="mb-4">
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control mt-2"
                                                              placeholder="Key Factor"
                                                              id="exampleInputfirstname"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-4">
                                                        <div className="mb-4">
                                                          <div className="form-group">
                                                            <input
                                                              type="text"
                                                              className="form-control mt-2"
                                                              placeholder="Key Factor"
                                                              id="exampleInputfirstname"
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="mb-4">
                                                      <div className="form-group">
                                                        <label htmlFor="phoneno">
                                                          <span className="mandatory-star me-1">
                                                            *
                                                          </span>
                                                          Tell us your purpose
                                                          with OKR ?
                                                        </label>
                                                        <textarea
                                                          className="form-control"
                                                          placeholder="Describe your purpose with OKR..."
                                                          id="floatingTextarea"
                                                          rows="2"
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                    <div className="mb-4">
                                                      <div className="form-group">
                                                        <label htmlFor="phoneno">
                                                          <span className="mandatory-star me-1">
                                                            *
                                                          </span>
                                                          What solution are you
                                                          providing ?
                                                        </label>
                                                        <textarea
                                                          className="form-control"
                                                          placeholder="Describe your company...."
                                                          id="floatingTextarea"
                                                          rows="2"
                                                        ></textarea>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </div>
                                                <div className="modal-footer border-0">
                                                  <NavLink to="/dashboard">
                                                    <button
                                                      type="submit"
                                                      className="btn sign-up-button-color text-white px-5"
                                                    >
                                                      Submit
                                                    </button>
                                                  </NavLink>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
