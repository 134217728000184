import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useParams } from "react-router-dom";
import settingProfile from "../../assets/images/Icons/SVG/Group 22974.svg";
import API from "../../Api/Api";

export default function SettingsDetails() {
  const params = useParams();
  const DeptId = params?.id;
  console.log(params.tab,"-------------");
  const [isEditable, setIsEditable] = useState(true);
  const [SingleUserInfo, setUserInfo] = useState(null);
  const [Status, setStatus] = useState(true);
  const [inActiveReason, setInActiveReason] = useState();
  const handlredit = () => {
    setIsEditable(false);
    // setStatus(!Status);
  };

  const getUserInfo = () => {
    API.userManagement
      .GetSingleUserInfo({ DeptId })
      .then((response) => {
        console.log(response);
        setUserInfo(response.data.data);
        setInActiveReason(response.data.data?.inactivation_reason);
        setStatus(response.data.data.isDepartmentActive);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  console.log(SingleUserInfo, "SingleUserInfo");
  const UpdateUser = () => {
    API.userManagement
      .updateUserInfo({
        data: {
          _id: DeptId,
          isDepartmentActive: Status,
          inactivation_reason: inActiveReason,
        },
      })
      .then((response) => {
        console.log(response);
        setIsEditable(!isEditable);
        setStatus(response.data.data.isDepartmentActive);
        getUserInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12">
              <div className="d-flex align-items-center ps-3">
                <NavLink className="" to="/settings" state={params?.tab}>
                  <div className="backArrow me-2">
                    <i className="fa fa-solid fa-chevron-left textDark"></i>
                  </div>
                </NavLink>
                <h3 className="mb-0 fw-bold">User Management</h3>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-xl-12">
              <div className="row my-2">
                <div className="col-6">
                  <h3 class="textDarkBlack mb-1 fw-bold">Edit User</h3>
                  <small className="textDarkGrey">Manage your users here</small>
                </div>
                <div className="col-6 text-end">
                  {isEditable ? (
                    <button
                      // to="/settings-details-edit"
                      type="button"
                      className="btn btnOrganization py-1 w-auto px-5"
                      onClick={() => handlredit()}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      // to="/settings-details-edit"
                      type="button"
                      className="btn btnOrganization py-1 w-auto px-5"
                      onClick={() => UpdateUser()}
                    >
                      save
                    </button>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 mb-3">
                  <img
                    src={
                      SingleUserInfo?.profileImageUrl
                        ? `${process.env.REACT_APP_BASE_URL}/images/${SingleUserInfo?.profileImageUrl}`
                        : settingProfile
                    }
                    className="setting-Profile-img"
                  />
                </div>
                <div className="col-lg-9">
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">Department Name</p>
                    <p className="textDarkBlack">
                      {SingleUserInfo?.dept_name
                        ? SingleUserInfo?.dept_name
                        : "-"}
                    </p>
                  </div>
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">
                      Enter department owner's email
                    </p>
                    <p className="textDarkBlack">
                      {SingleUserInfo?.email ? SingleUserInfo?.email : "-"}
                    </p>
                  </div>
                  {/* <div className="mb-4">
                    <p className="textLightGrey mb-1">User Role</p>
                    <p className="textDarkBlack">Department Owner</p>
                  </div> */}
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">Status</p>
                    {isEditable ? (
                      <>
                        {SingleUserInfo?.isDepartmentActive === true ? (
                          <span className="mb-0 activeStatus">Active</span>
                        ) : SingleUserInfo?.isDepartmentActive === false ? (
                          <span className="mb-0 inactiveStatus">Inactive</span>
                        ) : (
                          <span className="mb-0 pendingStatus">Pending</span>
                        )}
                      </>
                    ) : (
                      <label className="switch custom-toggle-switch-with-text">
                        <input
                          type="checkbox"
                          id="togBtn"
                          checked={!Status}
                          onChange={(e) => {
                            setStatus(e.target.checked === true ? false : true);
                          }}
                        />
                        <div className="slider round"></div>
                      </label>
                    )}
                  </div>
                  {!Status ? (
                    <div className="mb-4">
                      <p className="textLightGrey mb-1">
                        Reason for inactivating this user
                      </p>
                    </div>
                  ) : null}
                  {!Status ? (
                    <textarea
                      className="differentiator text-start p-3 w-50 border-0"
                      rows={3}
                      value={inActiveReason}
                      onChange={(e) => setInActiveReason(e.target.value)}
                      readOnly={isEditable} // Set readOnly based on the value of isEditable
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
