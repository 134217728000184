import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import departmentIcon from "../../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import { useFormik } from "formik";
import API from "../../../Api/Api";
import RouteName from "../../../Routes/Routename";

const ShareAccess = (props) => {
  const [departmentList, setDepartmentList] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validate = (values) => {
    console.log(values, "value");
    const regex = /^[^\s].*$/;
    const paragraphReg=/^[^\s]+(\s+[^\s]+)*$/

    const errors = {};
    if (!values.about_us) {
      errors.about_us = "Please enter about us";
    } else if (!regex.test(values.about_us)) {
      errors.about_us = "Cannot start with a space";
    }
    if (!values.mission) {
      errors.mission = "Please enter mission";
    } else if (!paragraphReg.test(values.mission)) {
      errors.mission = "Cannot start with a space";
    }
    if (!values.vision) {
      errors.vision = "Please enter vision";
    } else if (!paragraphReg.test(values.vision)) {
      errors.vision = "Cannot start with a space";
    }
    if (!values.firstKeyFactor) {
      errors.firstKeyFactor = "Please enter firstKeyFactor";
    } else if (!regex.test(values.firstKeyFactor)) {
      errors.firstKeyFactor = "Cannot start with a space";
    }

    if (!values.secondKeyFactor) {
      errors.secondKeyFactor = "Please enter secondKeyFactor";
    } else if (!regex.test(values.secondKeyFactor)) {
      errors.secondKeyFactor = "Cannot start with a space";
    }

    if (!values.thirdKeyFactor) {
      errors.thirdKeyFactor = "Please enter thirdKeyFactor";
    } else if (!regex.test(values.thirdKeyFactor)) {
      errors.thirdKeyFactor = "Cannot start with a space";
    }
    if (!values.purpose) {
      errors.purpose = "Please enter purpose";
    } else if (!paragraphReg.test(values.purpose)) {
      errors.purpose = "Cannot start with a space";
    }
    if (!values.solution) {
      errors.solution = "Please enter solution";
    } else if (!paragraphReg.test(values.solution)) {
      errors.solution = "Cannot start with a space";
    }
    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      about_us: "",
      mission: "",
      vision: "",
      firstKeyFactor: "",
      secondKeyFactor: "",
      thirdKeyFactor: "",
      purpose: "",
      solution: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      shareInviteSubmitApi();
    },
    validate,
  });
  const getDeaprtmentInviteList = () => {
    API.Auth.GetAllOrgDepartmentsAPI()
      .then((response) => {
        setDepartmentList(response?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getDeaprtmentInviteList();
  }, []);
  const [deleteId, setDeleteId] = useState();
  const deleteDepartmentId = (id) => {
    setDeleteId(id);
  };
  console.log(deleteId);
  const deleteDepartment = () => {
    API.Auth.DeactivateDepartment({
      data: {
        _id: deleteId,
      },
    })
      .then((response) => {
        console.log(response, "-------");
        // document.querySelector(".btn-close").click();
        // document.querySelector("#staticBackdrop1").style.display = "none";
        document.querySelector("#DeleteDepartemnt").click();

        getDeaprtmentInviteList();
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (id) => {
    props.handleBack();
    navigate(`../${RouteName.Auth.Registration}?id=3&editId=${id}`, {
      replace: true,
    });
  };

  const shareInviteOnEmail = () => {
    setLoading(true);
    API.Auth.ShareInviteAPI({
      data: {
        flag: 5,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.org_user.org_Info_filled === false) {
          document.querySelector("#shareInvite").click();
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  // create api

  const shareInviteSubmitApi = () => {
    API.Auth.ShareInviteSubmitAPI({
      data: {
        about_us: formik.values.about_us,
        mission: formik.values.mission,
        vision: formik.values.vision,
        profileImageUrl: null,
        companyLogoUrl: null,
        differentiator: {
          firstKeyFactor: formik.values.firstKeyFactor,
          secondKeyFactor: formik.values.secondKeyFactor,
          thirdKeyFactor: formik.values.thirdKeyFactor,
        },
        purpose: formik.values.purpose,
        solution: formik.values.solution,
        org_Info_filled: true,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response, "responseresponse");
          document.querySelector(".btn-close").click();
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Share Invite</h2>
          <p className="textGrey">
            Make sure your objectives are crisp and clear
          </p>
          <div className="row">
            {departmentList &&
              departmentList?.map((item, index) => {
                return (
                  <>
                    <div className="col-xl-6 mb-1" key={index}>
                      <div className="row">
                        <div className="col-8">
                          <div className="setObjectiveListBox row p-3">
                            <div className="col-2 p-0">
                              <img src={departmentIcon} className="" />
                            </div>
                            <div className="col-10 setObjectiveListBox">
                              <p
                                className="mb-1"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item?.dept_name}
                              </p>
                              <p
                                className="textBlueLight1 mb-0"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex">
                            <img
                              src={editIcon}
                              onClick={() => handleEdit(item._id)}
                            />
                            <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => deleteDepartmentId(item._id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          {/* Button trigger modal */}
          <button
            type="button"
            className="btn btn sign-up-button-color text-white px-5 mt-3"
            onClick={(e) => shareInviteOnEmail(e)}
          >
            {loading ? "Please wait.." : " Share Invite"}
          </button>
          <button
            type="button"
            className="btn btn sign-up-button-color text-white px-5 mt-3"
            id="shareInvite"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            Share Invite #
          </button>
          {/* Modal */}
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0 p-4">
                <div className="modal-header py-0 border-0">
                  <h2 className="textBlue fw-normal">
                    Tell us about your company
                  </h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => formik.resetForm()}
                  />
                </div>
                <div className="modal-body text-start">
                  <form>
                    <div className="mb-4">
                      <div className="form-group">
                        <label htmlFor="phoneno">
                          <span className="mandatory-star me-1">*</span>
                          About your Company
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Describe your company...."
                          id="exampleInputfirstname"
                          name="about_us"
                          value={formik.values.about_us}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.errors.about_us && formik.touched.about_us ? (
                        <div className="text-danger">
                          {formik.errors.about_us}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-4">
                          <div className="form-group">
                            <label htmlFor="phoneno">
                              <span className="mandatory-star me-1">*</span>
                              Company Mission
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Mention your Company Mission..."
                              id="floatingTextarea"
                              rows="2"
                              name="mission"
                              value={formik.values.mission}
                              onChange={formik.handleChange}
                            ></textarea>
                          </div>
                          {formik.errors.mission && formik.touched.mission ? (
                            <div className="text-danger">
                              {formik.errors.mission}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-4">
                          <div className="form-group">
                            <label htmlFor="phoneno">
                              <span className="mandatory-star me-1">*</span>
                              Company Vision
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Mention your Company Vision..."
                              id="floatingTextarea"
                              rows="2"
                              name="vision"
                              value={formik.values.vision}
                              onChange={formik.handleChange}
                            ></textarea>
                          </div>
                          {formik.errors.vision && formik.touched.vision ? (
                            <div className="text-danger">
                              {formik.errors.vision}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label htmlFor="phoneno">
                        <span className="mandatory-star me-1">*</span>
                        Mention the 3 differentiator of your company ?
                      </label>
                      <div className="col-4">
                        <div className="mb-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control mt-2"
                              placeholder="differentiator"
                              id="exampleInputfirstname"
                              name="firstKeyFactor"
                              value={formik.values.firstKeyFactor}
                              onChange={formik.handleChange}
                            />
                          </div>
                          {formik.errors.firstKeyFactor &&
                          formik.touched.firstKeyFactor ? (
                            <div className="text-danger">
                              {formik.errors.firstKeyFactor}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control mt-2"
                              placeholder="differentiator"
                              id="exampleInputfirstname"
                              name="secondKeyFactor"
                              value={formik.values.secondKeyFactor}
                              onChange={formik.handleChange}
                            />
                          </div>
                          {formik.errors.secondKeyFactor &&
                          formik.touched.secondKeyFactor ? (
                            <div className="text-danger">
                              {formik.errors.secondKeyFactor}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control mt-2"
                              placeholder="differentiator"
                              id="exampleInputfirstname"
                              value={formik.values.thirdKeyFactor}
                              name="thirdKeyFactor"
                              onChange={formik.handleChange}
                            />
                          </div>
                          {formik.errors.thirdKeyFactor &&
                          formik.touched.thirdKeyFactor ? (
                            <div className="text-danger">
                              {formik.errors.thirdKeyFactor}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-group">
                        <label htmlFor="phoneno">
                          <span className="mandatory-star me-1">*</span>
                          Tell us your purpose with OKR ?
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Describe your purpose with OKR..."
                          id="floatingTextarea"
                          rows="2"
                          value={formik.values.purpose}
                          name="purpose"
                          onChange={formik.handleChange}
                        ></textarea>
                      </div>
                      {formik.errors.purpose && formik.touched.purpose ? (
                        <div className="text-danger">
                          {formik.errors.purpose}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <div className="form-group">
                        <label htmlFor="phoneno">
                          <span className="mandatory-star me-1">*</span>
                          What solution are you providing ?
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Describe your company...."
                          id="floatingTextarea"
                          rows="2"
                          value={formik.values.solution}
                          name="solution"
                          onChange={formik.handleChange}
                        ></textarea>
                      </div>
                      {formik.errors.solution && formik.touched.solution ? (
                        <div className="text-danger">
                          {formik.errors.solution}
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
                <div className="modal-footer border-0">
                  {/* <NavLink to="/dashboard"> */}
                  <button
                    type="submit"
                    className="btn sign-up-button-color text-white px-5"
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareAccess;
