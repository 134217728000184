import React, { useState, useEffect } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import IncreaseBusinessIcon from "../../assets/images/Icons/SVG/Selected Org.svg";
import checkListIcon from "../../assets/images/Icons/Pics/Department/SLA.png";
import API from "../../Api/Api";
import { useSelector } from "react-redux";
import useTaskScoreAverage from "../comman/KeyResultScoreLogic.js";

export default function TimelyDelivery() {
  const [keyResultList, setKeyResultList] = useState(null);
  const postData = useSelector((state) => state.date.departmentData);
  const deptInfo = useSelector((state) => state.date.objectiveInfo);
  const [avrageCal, setAvrageCall] = useState("");
const navigate =useNavigate();
  const { id } = useParams();
  const { getTaskScoreAverage } = useTaskScoreAverage();
  const getGeyResultList = () => {
    API.Department.GetAllKeyResultAPI(id)
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setKeyResultList(response.data.data);
          const average =  getTaskScoreAverage(response.data.data);
          setAvrageCall(average);
        }
      })
      .catch((err) => {
        if(err.response.status===403){
          navigate("/");
          }
        console.log(err);
      });
  };
  useEffect(() => {
    getGeyResultList();
  }, []);

  
  const indexToLetter = (index) => String.fromCharCode(97 + index);
  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12 d-sm-flex justify-content-between">
              <div className="d-flex align-items-center ps-3 mb-lg-0 mb-3">
                <NavLink className="" to="/department">
                  <div className="backArrow me-2">
                    <i className="fa fa-solid fa-chevron-left textDark"></i>
                  </div>
                </NavLink>
                <h3 className="mb-0 fw-bold">{postData.DeptName}</h3>
              </div>

              <div className="d-flex align-items-center me-2">
              <img
                  src={
                    postData?.DeptImage ? `${process.env.REACT_APP_BASE_URL}/images/${postData?.DeptImage}`  : departmentImg
                  }
                  className="me-3  Organization-img"
                />
                <div>
                  <small className="lightGrey mb-0">
                    {postData?.DeptName} Owner
                  </small>
                  <h5 className="textDarkBlack mb-1 fw-bold">
                    {postData.fullname ? postData.fullname : "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-xl-12">
              <h3 class="textDarkBlack mb-1 fw-bold">Departmental Objective</h3>
              <small className="textDarkGrey">
                Below are the list of your key result along with the
                departmental objective
              </small>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-sm-flex align-items-center">
                    <NavLink
                      className="text-decoration-none "
                      to={`/department-details/${postData?.id}`}
                    >
                      <h4 className="mb-0 fw-bold textBlueLight1 mb-sm-0 mb-3">
                        <img src={IncreaseBusinessIcon} className="me-2" />

                        {deptInfo?.objName}
                      </h4>
                    </NavLink>
                    <div className="backArrow ms-3 d-sm-block d-none">
                      <i className="fa fa-solid fa-chevron-right textBlack"></i>
                    </div>

                    <h4 className="mb-0 fw-bold textBlueLight">
                      <img src={checkListIcon} className="me-2" />
                      Objective {deptInfo?.index+1}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row align-items-center progressCard p-sm-3 p-2 mt-4">
                <div className="col-sm-10 mb-3 mb-sm-0">
                  <h4 className="textBlueLight">{deptInfo?.KeyResult}</h4>
                  <p className="textDarkBlack mb-0">
                   {deptInfo?.description}
                  </p>
                </div>
                <div className="col-sm-2">
                  <h3 className="textBlueLight fw-bold mb-0 ms-4"> {Math.round(avrageCal*100)/100}%</h3>
                </div>
              </div>

              <div className="position-relative">
                {keyResultList &&
                  keyResultList.map((item, index) => {
                    return (
                      <div
                        className="row d-flex align-items-center justify-content-center mt-3"
                        key={index}
                      >
                        <div className="col-lg-1 d-flex justify-content-center">
                          <div className="organizationNo">
                            {" "}
                            {indexToLetter(index)}
                          </div>
                          <div className="vLine"></div>
                        </div>
                        <div className="col-lg-11 bg-light-blue ">
                          <div className="row p-3 align-items-center">
                            <div className="col-12">
                              <p className="textGrey">
                                <span className="textLightGrey">Task:</span>{" "}
                                {item?.objective_initiative_task}
                              </p>
                              <div className="row p-3 progressCard shadow-none ">
                                <div className="col-lg-5 col-sm-6 col-12 mb-3">
                                  <p className="textGrey mb-1">
                                   Key Result
                                  </p>
                                  <p className="textDarkBlack mb-0">
                                  {item?.key_result}
                                  </p>
                                </div>
                                <div className="col-lg-2 col-sm-6 col-12 mb-3">
                                  <p className="textGrey mb-1 ps-4">Owner</p>
                                 
                                    {/* <div className="d-flex align-items-center justify-content-center"> */}
                                    <div className="d-flex">

                                      {item?.owner_department.map(
                                        (item, index) => {
                                          return (
                                            <div key={index}>
                                              <img
                                                src={
                                                  item?.profileImageUrl
                                                    ? item?.profileImageUrl
                                                    : departmentImg
                                                }
                                                className="ownerImg swiper-slide-img"
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12 mb-3">
                                  <p className="textGrey">
                                    Last Two Week Score
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <h3 className="bg-light-blue px-3 py-2 textBlueLight1 mb-0">
                                      {item?.second_last_week_score}
                                    </h3>
                                    <div className="scoreLine mx-2"></div>
                                    <h3 className="bg-light-blue px-3 py-2 textBlueLight1 mb-0">
                                      {item?.last_week_score}
                                    </h3>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <p className="textGrey">Current Score</p>

                                  <h3 className="bg-light-blue px-4 py-2 textBlueLight d-inline mt-1 currentScoreInput">
                                    {item?.current_score !=null ?item?.current_score :0}%
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
