/* eslint-disable */
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useLocation } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import API from "../../Api/Api";
import NodataFound from "../comman/NodataFound";

export default function Settings() {
  const location = useLocation();

  const [userList, setuserList] = useState(null);
  const [activeList, setactiveList] = useState(null);
  const [inactiveList, setinactiveList] = useState(null);
  const [PendingList, setPendingList] = useState(null);

  const [selectedTab, setSelectedTab] = useState("all");

  const getUsersList = () => {
    if (selectedTab === "all") {
      API.userManagement
        .GetDepartmentListByStatusAPI({
          data: {
            all_users: true,
          },
        })
        .then((response) => {
          console.log(response);
          setuserList(response?.data?.data);
        })
        .catch((err) => console.log(err));
    } else if (selectedTab === "active") {
      API.userManagement
        .GetDepartmentListByStatusAPI({
          data: {
            active_users: true,
          },
        })
        .then((response) => {
          console.log(response);
          setactiveList(response?.data?.data);
        })
        .catch((err) => console.log(err));
    } else if (selectedTab === "inactive") {
      API.userManagement
        .GetDepartmentListByStatusAPI({
          data: {
            inactive_users: true,
          },
        })
        .then((response) => {
          console.log(response);
          setinactiveList(response?.data?.data);
        })
        .catch((err) => console.log(err));
    } else if (selectedTab === "pending") {
      API.userManagement
        .GetDepartmentListByStatusAPI({
          data: {
            pending_users: true,
          },
        })
        .then((response) => {
          console.log(response);
          setPendingList(response?.data?.data);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    // Fetch initial data when the component mounts
    getUsersList();
  }, [selectedTab]); // Add selectedTab to the dependency array

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">Settings</h3>
              </div>
            </div>
            <h3 class="textDarkBlack my-4 fw-bold">User Management</h3>
            <div className="row">
              <div className="col-12">
                <ul
                  className="nav nav-tabs nav-tabs-custom mt-sm-5 mt-3 mt-xl-0"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.state === "All" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#All"
                      role="tab"
                      onClick={() => handleTabClick("all")}
                    >
                      <span>All Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.state === "Active" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#Active"
                      role="tab"
                      onClick={() => handleTabClick("active")}
                    >
                      <span>Active Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.state === "Inactive" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#Inactive"
                      role="tab"
                      onClick={() => handleTabClick("inactive")}
                    >
                      <span>Inactive Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.state === "Pending" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      href="#Pending"
                      role="tab"
                      onClick={() => handleTabClick("pending")}
                    >
                      <span>Pending Users</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content text-muted mt-3">
                  <div className="tab-pane active" id="All" role="tabpanel">
                    {userList?.length > 0 ? (
                      <div className="row mt-4">
                        {userList &&
                          userList?.map((item, index) => {
                            return (
                              <div className="col-xl-4 mb-3" key={index}>
                                <NavLink
                                  className="text-decoration-none"
                                  to={`/settings-details/${item?._id}/All`}
                                >
                                  <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          item?.profileImageUrl
                                            ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                            : departmentImg
                                        }
                                        className="me-3 Organization-img"
                                      />
                                      <div>
                                        <h3 className="textDarkBlack mb-1 fw-bold msm">
                                          {item?.dept_name}
                                        </h3>
                                        <p className="lightGrey mb-0">
                                          {item?.fullName
                                            ? item?.fullName
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    {item?.isDepartmentActive === true ? (
                                      <p className="mb-0 activeStatus">
                                        Active
                                      </p>
                                    ) : item?.isDepartmentActive === false ? (
                                      <p className="mb-0 inactiveStatus">
                                        Inactive
                                      </p>
                                    ) :item?.isDepartmentActive ===true && item?.joined === false ? (
                                      <p className="mb-0 inactiveStatus">
                                        Pending
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <NodataFound />
                    )}
                  </div>
                  <div className="tab-pane" id="Active" role="tabpanel">
                    {activeList?.length > 0 ? (
                      <div className="row mt-4">
                        {activeList &&
                          activeList?.map((item, index) => {
                            return (
                              <div className="col-xl-4 mb-3" key={index}>
                                <NavLink
                                  className="text-decoration-none"
                                  to={`/settings-details/${item?._id}/Active`}
                                >
                                  <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          item?.profileImageUrl
                                            ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                            : departmentImg
                                        }
                                        className="me-3 Organization-img"
                                      />

                                      <div>
                                        <h3 className="textDarkBlack mb-1 fw-bold">
                                          {item.dept_name}
                                        </h3>
                                        <p className="lightGrey mb-0">
                                          {item?.fullName
                                            ? item?.fullName
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <p className="mb-0 activeStatus">Active</p>
                                  </div>
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <NodataFound />
                    )}
                  </div>
                  <div className="tab-pane" id="Inactive" role="tabpanel">
                    {inactiveList?.length > 0 ? (
                      <div className="row mt-4">
                        {inactiveList &&
                          inactiveList.map((item, index) => {
                            return (
                              <div className="col-xl-4 mb-3" key={index}>
                                <NavLink
                                  className="text-decoration-none"
                                  to={`/settings-details/${item?._id}/Inactive`}
                                >
                                  <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          item?.profileImageUrl
                                            ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                            : departmentImg
                                        }
                                        className="me-3 Organization-img"
                                      />
                                      <div>
                                        <h3 className="textDarkBlack mb-1 fw-bold">
                                          {item?.dept_name}
                                        </h3>
                                        <p className="lightGrey mb-0">
                                          {item?.fullName
                                            ? item?.fullName
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <p className="mb-0 inactiveStatus">
                                      Inactive
                                    </p>
                                  </div>
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <NodataFound />
                    )}
                  </div>
                  <div className="tab-pane" id="Pending" role="tabpanel">
                    {PendingList?.length > 0 ? (
                      <div className="row mt-4">
                        {PendingList &&
                          PendingList.map((item, index) => {
                            return (
                              <div className="col-xl-4 mb-3" key={index}>
                                <NavLink
                                  className="text-decoration-none"
                                  to={`/settings-details/${item?._id}/Pending`}
                                >
                                  <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={
                                          item?.profileImageUrl
                                            ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                            : departmentImg
                                        }
                                        className="me-3 Organization-img"
                                      />
                                      <div>
                                        <h3 className="textDarkBlack mb-1 fw-bold">
                                          {item?.dept_name}
                                        </h3>
                                        <p className="lightGrey mb-0">
                                          {item?.fullName
                                            ? item?.fullName
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <p className="mb-0 pendingStatus">
                                      Pending
                                    </p>

                                    
                                  </div>
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <NodataFound />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
