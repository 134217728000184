import React, { useState, useEffect } from "react";
import LoginBanner from "../comman/LoginBanner";
import { NavLink, useNavigate } from "react-router-dom";
import RouteName from "../../Routes/Routename";
import API from "../../Api/Api";
// import Validation from "../../Constants/Regex";

export default function Login() {
  const initialValue = { email: "" };
  const [formValues, setformValues] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [apierror, setApierror] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
    setApierror("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);

      console.log(formValues);
      API.Auth.LoginManagementAPI({
        data: {
          email: formValues.email,
        },
      })
        .then((response) => {
          console.log(response.data, "Hello");

          if (response.data.statusCode === 200) {
            console.log(response);
            navigate(`/verify-otp`);
            localStorage.setItem("EmailID",formValues.email);
            localStorage.setItem(
              "managementUser",
              JSON.stringify(response.data.data)
            );
            setLoading(false);
          } else {
            console.log(response.data);
            setApierror(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          setApierror(err.response.data.message);
          setLoading(false);
        });
    }
  }, [formErrors, isSubmit]);

  const validate = (values) => {
    const error = {};
    const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    if (!values.email) {
      error.email = "Please enter email ID";
    } else if (!regex.test(values.email)) {
      error.email = "This is not a valid email format";
    }
    return error;
  };

  return (
    <div className="login-page">
      <div className="container-fluid main-container vh-100 p-0">
        <div className="row h-100">
          <LoginBanner />

          <div className="col-lg-6 col-12 vh-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="login-form-container">
                <div className="text-center">
                  <h1 className="bannerHeading fs-3 mb-5">
                    EASY<span className=" fs-3">OKR</span>
                  </h1>
                </div>
                <h2 className="mb-2">Login </h2>
                <div className="mb-4"></div>

                {/* { Object.keys(formErrors).length === 0 && isSubmit ? (<div> Signed in successfully</div>) : ( <pre>{JSON.stringify(formValues)}</pre>) } */}

                <form>
                  <div className="mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Enter registered E-Mail ID
                    </label>

                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formValues.email}
                      onChange={handleChange}
                      autoComplete="off"
                      placeholder="Enter your E-Mail ID"
                      className="form-control formControl"
                      aria-describedby="emailHelp"
                    />

                    <p className="text-danger font-13 pt-1 mb-0 ps-3">
                      {formErrors.email}
                    </p>
                    <p className="text-danger font-13 pt-1 mb-0 ps-3">
                      {apierror}
                    </p>
                  </div>

                  <button
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                    className="btn sign-up-button-color text-white w-100 mt-2"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Verify via OTP"}
                  </button>

                  {/* <p>{message}</p> */}

                  {/* <div className="form-check mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Stay Logged In
                    </label>
                  </div> */}
                  <div className="mt-4">
                    <span className="">Don’t have an account?</span>
                    <br />
                    <NavLink
                      className="btn p-0 text-decoration-underline fw-600"
                      to={`${RouteName.Auth.Registration}?id=0`}
                    >
                      Let’s Register
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
