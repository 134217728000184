import React, { useEffect, useMemo, useState } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import departmentIcon from "../../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import { useFormik } from "formik";
import API from "../../../Api/Api";
import RouteName from "../../../Routes/Routename";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useLocation, useNavigate } from "react-router-dom";
const DefineDepartment = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("editId");

  const [departmentList, setDepartmentList] = useState(null);
  const [departmentData, setDepartmentDataByID] = useState(null);
  const [isEditing, setEditing] = useState(false); // New state for edit mode
  const [editingId, setEditingId] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const navigate = useNavigate();
  const validate = (values) => {

    const errors = {};
    const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const spaceReg = /^\S.*\S$/;
    if (!values.dept_name) {
      errors.dept_name = "Please enter department name";
    } else if (!spaceReg.test(values.dept_name)) {
      errors.dept_name = "Cannot start & end with a space";
    }
    if (!values.email) {
      errors.email = "Please enter email";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      dept_name: "",
      email: "",
    },
    onSubmit: () => {
      // handleSave();
      if (editingId) {
        updateOrganization();
      } else {
        createDepartment();
      }
    },
    validate,
  });

  const createDepartment = () => {
    API.Auth.CreateDepartment({
      data: {
        dept_name: formik.values.dept_name,
        email: formik.values.email,
        flag_dept: 4,
      },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          console.log(response, "response");
          getAlldepartments();
          formik.resetForm();
        } else {
          // Handle error or provide feedback to the user
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const getAlldepartments = async () => {
    await API.Auth.GetAllOrgDepartmentsAPI()
      .then((response) => {
        setDepartmentList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSingledepartmentById = () => {
    if (editId) {
      API.Auth.GetSingleDeptInfo({ editId })
        .then((response) => {
          setDepartmentDataByID(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getAlldepartments();
  }, []);

  useEffect(() => {
    getSingledepartmentById();
  }, [editId]);

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  const deleteDepartment = () => {

    API.Auth.DeactivateDepartment({
      data: {
        _id: deleteId,
      },
    })
      .then((response) => {
        getAlldepartments();
        document.querySelector("#DeleteDepartemnt").click();
        formik.resetForm();
        setEditing(false);
        setEditingId("");
      })
      .catch((err) => console.log(err));

  };

  const handleEdit = (id) => {
    const objectiveToEdit = departmentList.find((item) => item._id === id);
    if (objectiveToEdit) {
      formik.setValues({
        dept_name: objectiveToEdit.dept_name,
        email: objectiveToEdit?.email,
      });
      setEditing(true);
      setEditingId(id);
    }
  };
  useEffect(() => {
    if (editId && departmentData) {
      formik.setValues({
        dept_name: departmentData?.dept_name,
        email: departmentData?.email,
      });
      setEditing(true);
      setEditingId(editId);
    }
  }, [editId, departmentData]);

  const updateOrganization = () => {
    const objectiveToEdit = departmentList.find(
      (item) => item._id === editingId
    );
    if (
      objectiveToEdit.email === formik.values.email &&
      objectiveToEdit.dept_name === formik.values.dept_name
    ) {
      formik.resetForm();
      setEditing(false);
      setEditingId("");
    } else {
      API.Auth.UpdateDepartment({
        data: {
          _id: editingId,
          dept_name: formik.values.dept_name,
          // email: formik.values.email,
        },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            console.log(response, "response");
            getAlldepartments();
            formik.resetForm();
            setEditing(false);
            setEditingId("");
          } else {
            // Handle error or provide feedback to the user
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };
  const handleNextButton = () => {
    props.handleNext();
    navigate(`../${RouteName.Auth.Registration}?id=4`, { replace: true });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    formik.resetForm();
    setEditing(false);
    setEditingId("");
  };
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Define Department</h2>
          {/* <p className="textGrey">
            Make sure your objectives are crisp and clear
          </p> */}
          <div className="row">
            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
              <p className="textBlueLight">
                Enter the department name and assign it to respective owners
              </p>

              <form>
                <div className="mb-4">
                  <div className="form-group">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label textLightGrey mb-1"
                    >
                      Department Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter department name"
                      id="exampleInputfirstname"
                      name="dept_name"
                      value={formik.values.dept_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.errors.dept_name && formik.touched.dept_name ? (
                    <div className="text-danger">{formik.errors.dept_name}</div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label textLightGrey mb-1"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter department owner's email address"
                      id="exampleInputfirstname"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      readOnly={isEditing ? true : false}
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                {!isEditing ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn set-objective-button w-100"
                      onClick={formik.handleSubmit}
                    >
                      Add Department
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn set-objective-button px-5"
                      onClick={(e) => handleCancel(e)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-white px-5"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
              <p className="textBlueLight mt-3">
                <span className="text-decoration-underline">Note:</span> We
                suggest you to add not more than 5 objectives making it
                achievable.
              </p>
            </div>
            <div className="col-xl-6 ps-xl-5">
              <p className="textBlueLight">
                Here are the list of your departments and their departmental
                leaders
              </p>
              {departmentList?.length == 0 ? (
                <div className="row">
                  <div className="col-12 text-center py-5">
                    <img src={setObjective} />
                  </div>
                </div>
              ) : (
                <>
                  {departmentList?.map((item, index) => {
                    console.log(item, "item");
                    return (
                      <div
                        className="row d-flex align-items-center w-100 mb-2"
                        key={index}
                      >
                        <div className="col-10">
                          <div className="setObjectiveListBox row p-3">
                            <div className="col-2 p-0">
                              <img src={departmentIcon} className="" />
                            </div>
                            <div className="col-10 setObjectiveListBox">
                              <p
                                className="mb-1"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item?.dept_name}
                              </p>
                              <p
                                className="textBlueLight1 mb-0"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex">
                            <img
                              src={editIcon}
                              onClick={() => handleEdit(item._id)}
                            />

                            <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => handleDelete(item._id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              <button
                className={`btn ${
                  departmentList?.length === 0
                    ? "sign-up-button-color-onempty"
                    : "sign-up-button-color"
                } text-light w-100 mt-3`}
                onClick={() => handleNextButton()}
                disabled={departmentList?.length === 0}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefineDepartment;
