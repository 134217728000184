/* eslint-disable */
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import organizationMession from "../../assets/images/Icons/SVG/BlueIcons -20-08.svg";
import organizationVision from "../../assets/images/Icons/SVG/BlueIcons -06-47.svg";

import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import API from "../../Api/Api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Organization() {
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(false);

  const validate = (values) => {
    console.log(values, "value");
    const errors = {};
    // const regex = /^[^\s].*$/;
    const spaceReg = /^\S.*\S$/;
    const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

    if (!values.objective_name) {
      errors.objective_name = "Please enter objective name";
    } else if (!spaceReg.test(values.objective_name)) {
      errors.objective_name = "Cannot start & end with a space";
    }
    if (!values.description) {
      errors.description = "Please enter description";
    } else if (!paragraphReg.test(values.description)) {
      errors.description = "Cannot start & end with a space";
    }

    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      objective_name: "",
      description: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      createObjectives();
    },
    validate,
  });
  // console.log(organizationData[0]);
  const getOrgWithObj = () => {
    API.DashBoard.getOrgWithObjAPI()
      .then((resposne) => {
        if(resposne.data.statusCode ===200){
        setOrganizationData(resposne.data.data);
        }
      })
      .catch((err) => {console.log(err);
        if(err.response.status===403){
          navigate("/");
          }
      });
  };
  useEffect(() => {
    getOrgWithObj();
  }, []);

  const createObjectives = () => {
    setLoading(true);
    API.Auth.CreateOrgObjectiveAPI({
      data: {
        objective_name: formik.values.objective_name,
        description: formik.values.description,
        // flag:3
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          document.querySelector(".btn-close").click();
          console.log(response);
          setLoading(false);
          formik.resetForm();
          getOrgWithObj();
        } else {
          toast.error(response?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">Organization</h3>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-xl-5 mb-4">
                <h4 className="textDarkBlack">
                  List of organizational objective
                </h4>
                <div className="progressCard p-lg-4 p-2">
                  {organizationData &&
                    organizationData[0] &&
                    organizationData[0].companyDetails &&
                    organizationData[0]?.companyDetails.map((item, index) => {
                      return (
                        <div
                          className="bg-light-blue p-3 mb-2"
                          key={index}
                          style={{ overflowWrap: "break-word" }}
                        >
                          <p className="textBlueLight1 mb-0 fw-bold">
                            {item?.objective_name}
                          </p>
                          <p className="textDarkBlack mb-0">
                            {item?.description}
                          </p>
                        </div>
                      );
                    })}
                </div>
                <div className="mt-4" id="organizationModal">
                  {/* Button trigger modal */}
                  <button
                    type="button"
                    className="btn btnOrganization py-sm-3 p-1"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <i className="fa-solid fa-plus plus-circle me-3"></i>
                    Add a new Organization Objective
                  </button>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                    style={{ zIndex: "999999" }}
                  >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header border-0">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              formik.resetForm(), setLoading(false);
                            }}
                          />
                        </div>
                        <div className="modal-body px-5">
                          <h3 class="textDarkBlack mb-0">Add Objective</h3>
                          <small className="textLightGrey">
                            Once the organizational objective is established, it
                            remains relatively immutable, as alterations could
                            significantly impact the department-level
                            objectives.
                          </small>
                          <form>
                            <div className="mb-4 mt-4">
                              <div className="form-group">
                                <label className="textLightGrey mb-1">
                                  Objective Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  placeholder="Eg: Improve Productivity"
                                  id="exampleInputfirstname"
                                  name="objective_name"
                                  value={formik.values.objective_name}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              {formik.errors.objective_name &&
                              formik.touched.objective_name ? (
                                <div className="text-danger">
                                  {formik.errors.objective_name}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label className="textLightGrey mb-2">
                                Objective Description
                              </label>
                              <textarea
                                className="form-control"
                                placeholder="Write a one liner description of the above objective..."
                                id="floatingTextarea"
                                rows="2"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                              ></textarea>
                            </div>
                            {formik.errors.description &&
                            formik.touched.description ? (
                              <div className="text-danger">
                                {formik.errors.description}
                              </div>
                            ) : null}
                            <button
                              type="submit"
                              className="btn sign-up-button-color text-white px-5 mt-3"
                              onClick={formik.handleSubmit}
                              disabled={loading}
                            >
                              {loading ? "Please wait..." : "Publish"}
                            </button>
                          </form>
                        </div>
                        <div className="modal-footer border-0"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-7">
                <h4 className="textDarkBlack mb-3">My Company Details</h4>
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-3">
                      <div className="col-6">
                        <p className="text-center fs-5 textBlueLight1 fw-bold mt-3">
                          {organizationData &&
                            organizationData[0]?.organization_name}
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <img
                          src={
                            organizationData &&
                            organizationData[0]?.companyLogoUrl
                              ? `${process.env.REACT_APP_BASE_URL}/images/${organizationData[0]?.companyLogoUrl}`
                              : departmentImg
                          }
                          className="Organization-img-square  float-end me-1"
                        />
                      </div>
                    </div>

                    <p className="textSecondary">
                      {organizationData && organizationData[0]?.about_us}
                    </p>
                    <div className="bg-light-blue border p-3 mb-4">
                      <div className="d-lg-flex align-items-center">
                        <div className="text-center mb-3">
                          <img src={organizationMession} />
                          <p className="textBlack mb-0 mt-2">Mission</p>
                        </div>

                        <p className="textSecondary mb-0 ms-3">
                          {organizationData && organizationData[0]?.mission}
                        </p>
                      </div>
                    </div>
                    <div className="bg-light-blue border p-3 mb-4">
                      <div className="d-lg-flex align-items-center">
                        <div className="text-center mb-3">
                          <img src={organizationVision} />
                          <p className="textBlack mb-0 mt-2">Vision</p>
                        </div>

                        <p className="textSecondary mb-0 ms-3">
                          {organizationData && organizationData[0]?.vision}
                        </p>
                      </div>
                    </div>
                    <div className="bg-light-blue bg-transparent border p-3 mb-4">
                      <h5 className="textDarkBlack mb-3">
                        Our 3 Differentiator
                      </h5>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <p className="textBlueLight1 mb-0 differentiator py-2">
                            {organizationData &&
                              organizationData[0]?.differentiator &&
                              organizationData[0]?.differentiator
                                ?.firstKeyFactor}
                          </p>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <p className="textBlueLight1 mb-0 differentiator py-2">
                            {organizationData &&
                              organizationData[0]?.differentiator &&
                              organizationData[0]?.differentiator
                                ?.secondKeyFactor}
                          </p>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <p className="textBlueLight1 mb-0 differentiator py-2">
                            {organizationData &&
                              organizationData[0]?.differentiator &&
                              organizationData[0]?.differentiator
                                ?.thirdKeyFactor}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-light-blue bg-transparent border p-3 mb-4">
                      <h5 className="textDarkBlack mb-3">
                        Tell us your purpose with OKR ?
                      </h5>
                      <p className="textSecondary">
                        {organizationData && organizationData[0]?.purpose}
                      </p>
                    </div>
                    <div className="bg-light-blue bg-transparent border p-3 mb-4">
                      <h5 className="textDarkBlack mb-3">
                        What problem are we solving?
                      </h5>
                      <p className="textSecondary">
                        {organizationData && organizationData[0]?.solution}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
