import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../../Api/Api";
import otpSentIcon from "../../../assets/images/Icons/Pics/Icon awesome-check-circle.png";
import RouteName from "../../../Routes/Routename";

const EmailVerification = (props) => {

  const navigate = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("managementUser"));
  console.log(userInfo?.work_email,"userInfo");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [newOtpData, setnewOtpData] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popUpResponse, setPopupResponse] = useState("");

  const closePopup = () => {
    setPopupOpen(false);
  };
  function handleChange(event, index) {
    setFormErrors("");
    const { value } = event.target;
    console.log("value", value);
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value.slice(0, 1);
      return newOtp;
    });
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  }
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    console.log("newotp", newotp);
    setFormErrors("");

    if (!newotp) {
      setFormErrors("Please Enter otp");
    } else {
      try {
        API.Auth.RegisterAPI({
          data: {
            work_email: userInfo?.work_email,
            otp: newotp,
            flag: 2,
          },
        }).then((response) => {
          if (response?.data?.statusCode === 200) {
            console.log(response.data);
            // Show popup
            setPopupOpen(true);
            setPopupResponse("Email verified Successfully");
            // Close the popup and navigate after a delay
            setTimeout(() => {
              setPopupOpen(false);
              props.handleNext();
              navigate(`../${RouteName.Auth.Registration}?id=2`, {
                replace: true,
              });

              // Optionally, you can perform additional actions after the delay
              localStorage.setItem("managementToken", response.data.token);
            }, 1000);
          } else {
            setFormErrors("Please Enter valid otp");
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    console.log("otp", otp);
  }, [otp]);

  // ===== automatically close the popup after a certain period of time ===========
  useEffect(() => {
    if (isPopupOpen) {
      const timeoutId = setTimeout(() => {
        closePopup();
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [isPopupOpen]);

  // ===== Resend Otp ===========
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    if (counter) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
    // Time == 0 ? setotperror(true) : null;
  }, [counter]);

  const handleResendOtp = (e) => {
    setOtp(["", "", "", ""]);
    setFormErrors("");
    e.preventDefault();
    // openPopup()
    setCounter(60);
    try {
      API.Auth.ResendOtp({
        data: {
          work_email: userInfo?.work_email,
          resendOtp: true,
          flag: 2,
        },
      }).then((response) => {
        console.log(response.data);
        if (response?.data?.statusCode === 200) {
          setTimeout(() => {
            setPopupOpen(true);
          }, 1000);
          setnewOtpData(response?.data?.data);
          setPopupResponse("OTP sent successfully");
        } else {
          setFormErrors("Please Enter valid otp");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("otp", otp[0]);
    if (otp[0] === "") {
      console.log("error");
    }
  }, [otp]);
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-12 position-relative">
          {
            !isPopupOpen ? (
              <>
                {" "}
                <h2 className="textBlue">Email Verification</h2>
                <div className="col-12 mb-4">
                  <small className="textDark">
                    Enter 4 digit OTP sent on the email address
                    <br />
                    <b> {userInfo?.work_email}</b>
                    <p className="text-danger">{formErrors}</p>
                  </small>
                </div>
                <div className="col-12">
                  <div
                    className="row mb-4"
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    {otp.map((value, index) => (
                      <div className="col-3">
                        <input
                          key={index}
                          onkeypress="return onlyNumberKey(event)"
                          type="number"
                          placeholder="-"
                          pattern="[0-9.]+"
                          id={`otp-${index}`}
                          name={`otp-${index}`}
                          value={value}
                          onChange={(event) => handleChange(event, index)}
                          maxLength="1"
                          ref={inputRefs[index]}
                          className={`${
                            formErrors ? "border-danger" : "border-none"
                          } form-control custom-input-text-primary otp-input mb-0 text-center`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  className={`btn ${
                    otp.join("").length === 4
                      ? "sign-up-button-color"
                      : "sign-up-button-color-onempty"
                  } text-light w-100`}
                  onClick={(e) => handleVerifyOtp(e)}
                  disabled={otp.join("").length !== 4}
                >
                  Verify
                </button>
                {/* {counter === 0 ? ( */}
                {/* Resend OTP start */}
                {counter === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <p className="mt-2 mb-0">Didn&rsquo;t Receive OTP?</p>
                      <button
                        className="ms-1 btn p-0 text-decoration-underline"
                        onClick={(e) => handleResendOtp(e)}
                      >
                        {" "}
                        Resend OTP
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row text-center">
                    <p className=" mt-2 ">
                      Resend OTP in{" "}
                      <b>
                        {counter == 60
                          ? `00:60`
                          : counter >= 10
                          ? `00:${counter}`
                          : `00:0${counter}`}
                      </b>{" "}
                      Sec
                    </p>
                  </div>
                )}
                {/* Resend OTP end */}
                {/* ) : ( */}
                <>
                  {/* <div className="row text-center">
                      <p className=" mt-2 ">
                        Resend OTP in{" "}
                        <b>
                          {counter == 60
                            ? `00:60`
                            : counter >= 10
                            ? `00:${counter}`
                            : `00:0${counter}`}
                        </b>{" "}
                        Sec
                      </p>
                    </div> */}
                </>
                {/* )} */}
              </>
            ) : (
              <>
                <div
                  className="login-form-container"
                  // onClick={nextOpen}
                >
                  <div className="text-center mb-5">
                    <img src={otpSentIcon} />
                  </div>
                  <div className="text-center px-4 mt-2">
                    <h2 className="text-center fw-bold letter-spacing textDark">
                      {popUpResponse}{" "}
                    </h2>
                  </div>
                </div>
              </>
            )
            // </button>
          }
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
